<template>
  <div class="s_flex_bian_c m-b-20">
    <div class="s_flex_ali">
      <a-button
        type="primary"
        v-has:appletReleaseSynchro="() => (visible = true)"
        v-if="appids.length >= 1"
      >
        批量同步
      </a-button>
      <a-button
        type="primary"
        class="m-l-10"
        v-has:appletReleaseAllSynchro="() => (allvisible = true)"
      >
        一键同步
      </a-button>
      <p class="m-l-20">本月小程序提审剩余次数：{{ quotaNum.rest || 0 }}</p>
    </div>
    <!-- <div>
      <a-button type="primary" v-has:addCommunityMini="()=>{brandVisible=true;addType='add'}">
        创建小程序
      </a-button>
      <a-button type="primary" class="m-l-10" v-has:authCommunityMini="()=>{brandVisible=true;addType='authorize'}">
        授权小程序
      </a-button>
    </div> -->
  </div>
  <a-table
    :pagination="false"
    :loading="listLoading"
    :columns="columns"
    :data-source="data"
    class="m-b-20"
    :row-selection="rowSelection"
  >
    <template v-slot:action="{ record }">
      <a-dropdown>
        <a class="ant-dropdown-link">
          操作
          <DownOutlined />
        </a>
        <template v-slot:overlay>
          <a-menu>
            <a-menu-item>
              <a
                v-has:appletReleaseGetCodeImg="() => getExperience(record)"
                href="javascript:;"
                >体验版二维码</a
              >
            </a-menu-item>
            <a-menu-item>
              <a
                v-has:appletReleaseCode="
                  () => {
                    visible = true;
                    appids = [record.app_id];
                  }
                "
                href="javascript:;"
                >提交</a
              >
            </a-menu-item>
            <a-menu-item>
              <a
                v-has:appletCodeDetail="
                  () =>
                    $router.push({
                      path: '/appletCode/appletDetail/index',
                      query: {
                        brand_id: record.brand_id,
                        app_id: record.app_id,
                        miniapp_type: 101
                      }
                    })
                "
                href="javascript:;"
                >详情</a
              >
            </a-menu-item>
            <!-- <a-menu-item>
              <a v-has:updateCommunityMIni="()=>updateMini(record)" href="javascript:;">更新授权信息</a>
            </a-menu-item> -->
          </a-menu>
        </template>
      </a-dropdown>
    </template>
  </a-table>
  <a-pagination
    :style="{ textAlign: 'right' }"
    show-size-changer
    show-quick-jumper
    :current="search.page"
    :page-size="search.limit"
    :total="total"
    @change="pageCurrentChange"
    @showSizeChange="pageSizeChange"
    :show-total="(total) => `总数: ${total} `"
  />
  <a-modal
    v-model:visible="allvisible"
    title="代码提交"
    @ok="appletReleaseCode"
    centered
    :confirm-loading="confirmLoading"
    destroyOnClose
  >
    <a-form
      ref="ruleForm"
      :model="formData"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-item ref="user_desc" label="模板" name="user_desc">
        <a-input-search
          v-model:value="formData.user_desc"
          enter-button="选择"
          readonly
          @search="templateVisible = true"
        />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal
    v-model:visible="visible"
    title="代码提交"
    @ok="handleOk"
    centered
    :confirm-loading="confirmLoading"
    destroyOnClose
  >
    <a-form
      ref="ruleForm"
      :model="formData"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-item ref="user_desc" label="模板" name="user_desc">
        <a-input-search
          v-model:value="formData.user_desc"
          enter-button="选择"
          readonly
          @search="templateVisible = true"
        />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal
    v-model:visible="templateVisible"
    title="选择模板"
    centered
    :confirm-loading="confirmLoading"
    destroyOnClose
    :width="1200"
    :footer="null"
  >
    <appletTemplate v-on:success="success" />
  </a-modal>
  <a-modal
    v-model:visible="codeVisible"
    title="体验版二维码"
    centered
    destroyOnClose
  >
    <div style="display:flex;justify-content:center">
      <img :src="qrcode" alt="" />
    </div>
  </a-modal>
  <!-- 品牌商弹窗 -->
  <a-modal
    v-model:visible="brandVisible"
    title="品牌商"
    :width="1200"
    destroyOnClose
    centered
    :footer="null"
  >
    <selectBrand
      v-on:select="brandSelect"
      :checkout="false"
      :selectList="brandList"
    />
  </a-modal>
</template>
<script>
import config from "@/config";
import { DownOutlined } from "@ant-design/icons-vue";
import { ref, onMounted, inject, reactive, toRefs } from "vue";
import {
  getBusinessMIniApp,
  getExperienceQrcode,
  miniCodeWhole,
  getQuota,
  updateChannelMIniApp
} from "@/api/appletCode";
import appletTemplate from "@/components/appletTemplate/index";
import { $iscode } from "@/utils/app";
import { message } from "ant-design-vue";
// 初始化默认筛选项数值
let defSearch = {
  page: 1,
  limit: 10
};
export default {
  components: { DownOutlined, appletTemplate },
  setup(props, ctx) {
    // // 初始化 分页信息和筛选项信息
    let search = ref({ ...defSearch });
    // 列表数据和列头配置
    let data = ref([]);
    const state = reactive({
      qrcode: "",
      codeVisible: false,
      listLoading: false,
      total: 1,
      active: 1,
      visible: false,
      allvisible: false,
      templateVisible: false,
      confirmLoading: false,
      appids: [],
      quotaNum: 0,
      brandVisible: false,
      brandList: [],
      addType: "add"
    });
    let columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        width: 150
      },
      {
        title: "appID",
        dataIndex: "app_id",
        key: "app_id"
      },
      {
        title: "小程序名称",
        key: "nick_name",
        dataIndex: "nick_name"
      },
      {
        title: "品牌商编号",
        dataIndex: "brand_id",
        key: "brand_id"
      },
      {
        title: "品牌商名称",
        dataIndex: "brand_name",
        key: "brand_name"
      },
      {
        title: "创建时间",
        dataIndex: "create_time",
        key: "create_time"
      },
      {
        title: "操作",
        key: "action",
        slots: { customRender: "action" }
      }
    ];
    const $router = inject("$router");
    const $Modal = inject("$Modal");
    // 页面筛选项搜索
    const pageSearchChange = () => {
      search.value = { ...search.value, page: 1 };
      initData(search.value);
    };
    // 分页当前页切换
    const pageCurrentChange = (page, pageSize) => {
      search.value.page = page;
      initData(search.value);
    };
    // 分页当前页显示多少条切换
    const pageSizeChange = (current, limit) => {
      search.value.page = 1;
      search.value.limit = limit;
      initData(search.value);
    };
    const brandSelect = (e) => {
      const baseUrl = config.baseUrl[process.env.VUE_APP_type];
      state.brandList = [e];
      state.brandVisible = false;
      if (state.addType == "add") {
        window.open(
          baseUrl + "v2/miniappApply/applyPage?brand_id=" + e.brand_no
        );
      } else {
        window.open(baseUrl + "v2/wxAuth/authEntryPage?brand_id=" + e.brand_no);
      }
    };
    //小程序已授权列表
    const initData = async (values) => {
      state.listLoading = true;
      let res = await getBusinessMIniApp(values).then((res) => res.data);
      state.listLoading = false;
      if ($iscode(res)) {
        data.value = res.data.data;
        state.total = res.data.total;
      } else {
        message.error(res.msg);
      }
    };
    //查询当月提审限额（quota）和加急次数
    const getQuotaNum = async () => {
      let res = await getQuota().then((res) => res.data);
      if ($iscode(res)) {
        state.quotaNum = res.data;
      }
    };
    onMounted(() => {
      initData(search.value);
      getQuotaNum();
    });
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        state.appids = selectedRows.map((v) => v.app_id);
        console.log(state.appids);
      }
    };
    var form = ref();
    var formData = ref({});
    const success = (e) => {
      state.templateVisible = false;
      formData.value = e;
    };
    //更新小程序信息
    const updateMini = async (data) => {
      let result = await updateChannelMIniApp({ brand_id: data.brand_id })
        .then((res) => res.data)
        .catch((error) => error);
      if ($iscode(result, true)) {
        initData(search.value);
      }
    };
    const handleOk = async () => {
      state.confirmLoading = true;
      let result = await miniCodeWhole({
        ...formData.value,
        miniapp_type: 101,
        appids: JSON.stringify(state.appids)
      })
        .then((res) => res.data)
        .catch((error) => error);
      state.confirmLoading = false;
      console.log(result);
      if ($iscode(result, true)) {
        state.visible = false;
        initData(search.value);
      }
    };
    const appletReleaseCode = async () => {
      state.confirmLoading = true;
      let result = await miniCodeWhole({
        ...formData.value,
        miniapp_type: 101,
        appids: JSON.stringify([])
      })
        .then((res) => res.data)
        .catch((error) => error);
      state.confirmLoading = false;
      if ($iscode(result, true)) {
        state.allvisible = false;
        initData(search.value);
      }
    };
    const getExperience = async (data) => {
      let result = await getExperienceQrcode({
        appid: data.app_id,
        brand_id: data.brand_id,
        miniapp_type: 101
      })
        .then((res) => res.data)
        .catch((error) => error);
      state.qrcode = result.data.qrcode;
      state.codeVisible = true;
    };
    return {
      ...toRefs(state),
      search,
      rowSelection,
      data,
      columns,
      pageSearchChange,
      pageCurrentChange,
      pageSizeChange,
      form,
      success,
      formData,
      handleOk,
      appletReleaseCode,
      getExperience,
      brandSelect,
      updateMini
    };
  }
};
</script>
